define("bocce/mixins/kaltura-upload", ["exports", "bocce/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    kalturaUploadVideo: function (vidBlob, failureCallback, isFile, blobURL, encoding_video) {
      this.createToken().then(token => {
        if (!this.session) {
          this.session = window.session;
        }
        if (token) {
          let fd = new FormData();
          this.kSession = token.kSession;

          //Create backup link for plURL
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.video-backup-rec').attr('href', this.blobURL);
          fd.append('ks', this.kSession);
          fd.append('resume', 'false');
          fd.append('finalChunk', 'true');
          fd.append('resumeAt', '-1');
          fd.append('fileData', vidBlob);
          fd.append('uploadTokenId', token.id);
          /* eslint-disable-next-line ember/no-get */
          fd.append('fname', this.get('session.user.name').toLowerCase().replace(/ /g, '_') + '_section-' + this.get('session.course.id') + '_video_' + Math.round(new Date().getTime() / 1000));

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$.ajax({
            xhr: () => {
              var xhr = new window.XMLHttpRequest();
              xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                  var percentComplete = evt.loaded / evt.total;
                  if (typeof isFile === 'number') {
                    Ember.set(encoding_video, "progress", percentComplete);
                  } else {
                    this.set('uploadPercentage', percentComplete + '%');
                  }
                }
              }, false);
              return xhr;
            },
            type: 'POST',
            url: token.uploadUrl + '/api_v3/service/uploadtoken/action/upload',
            data: fd,
            processData: false,
            contentType: false
          }).done(data => {
            let dataJSON = this.xmlToJson(data);
            if (dataJSON.xml.result && dataJSON.xml.result.objectType === 'KalturaUploadToken') {
              this.createMediaEntry(token, vidBlob).then(videoInfo => {
                if (!videoInfo) {
                  this.failure(failureCallback, "Could not create media entry in Kaltura. Kaltura upload failed.");
                  return;
                }
                this.addToCategory('145227041', videoInfo.xml.result.rootEntryId);
                this.attachRtc({
                  name: videoInfo.xml.result.name,
                  id: videoInfo.xml.result.rootEntryId
                }, encoding_video, blobURL);
                Ember.$.ajax({
                  type: 'POST',
                  url: '/interface/email',
                  data: JSON.stringify({
                    to: _environment.default.APP.email.kalturaStatus,
                    from_name: 'BOCCE',
                    subject: `kaltura success`,
                    html: `kaltura success`,
                    ignoreAuth: true
                  }),
                  success() {
                    console.log("Successfully sent slack notification indicating kaltura upload result.");
                  },
                  error(jqXHR, textStatus) {
                    console.error('Unable to send slack notification indicating kaltura upload result.');
                  }
                });
              });
            } else {
              Ember.debug('Unable to create media entry for new video. Error Code:' + dataJSON.xml.result.error.code + ' Error Message: ' + dataJSON.xml.result.error.message);
              this.failure(failureCallback, 'Unable to create media entry for new video. Error Code:' + dataJSON.xml.result.error.code + ' Error Message: ' + dataJSON.xml.result.error.message);
            }
          }).fail(err => {
            this.failure(failureCallback, 'Kaltura upload failed.');
          });
        } else {
          Ember.debug('No session token provided. Aborting.');
          this.failure(failureCallback, 'Unable to get session token. Please contact support.');
        }
      });
    },
    failure(callback, message) {
      Ember.$.ajax({
        type: 'POST',
        url: '/interface/email',
        data: JSON.stringify({
          to: _environment.default.APP.email.kalturaStatus,
          from_name: 'BOCCE',
          subject: `kaltura failure`,
          html: `kaltura failure`
        }),
        success() {
          console.log("Successfully sent slack notification indicating no-requirements for this course.");
        },
        error(jqXHR, textStatus) {
          console.error('Unable to send slack notification indicating no-requirements for this course.');
        }
      });
      callback(message);
    },
    addToCategory: function (cat_id, entry_id) {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$.ajax({
        type: 'POST',
        url: 'https://www.kaltura.com/api_v3/service/categoryentry/action/add',
        data: {
          ks: this.kSession,
          categoryEntry: {
            entryId: entry_id,
            categoryId: cat_id,
            objectType: 'KalturaCategoryEntry'
          }
        },
        success: category => {
          let categoryJSON = this.xmlToJson(category);
          if (typeof categoryJSON === 'object' && categoryJSON.xml.result.error) {
            Ember.debug('Unable to add video category. Error Code:' + categoryJSON.xml.result.error.code + ' Error Message: ' + categoryJSON.xml.result.error.message);
          }
        },
        error: error => {
          Ember.debug('Category error: ' + error.responseText);
        }
      });
    },
    createMediaEntry: function (rToken, file) {
      /* eslint-disable-next-line ember/no-get */
      let userName = this.get('session.user.name').toLowerCase().replace(/ /g, '_');
      /* eslint-disable-next-line ember/no-get */
      let courseId = this.get('session.course.id');
      let data = {
        ks: this.kSession,
        entry: {
          'mediaType': 1,
          'name': file.name || userName + '_section-' + courseId + '_video_' + Math.round(new Date().getTime() / 1000),
          'description': 'User uploaded video from course ID: ' + courseId + ', uploaded by user: ' + userName
        }
      };
      return new Promise(resolve => {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          url: 'https://www.kaltura.com/api_v3/service/media/action/add',
          data: data,
          success: mediaEntry => {
            let mediaJSON = this.xmlToJson(mediaEntry);
            if (typeof mediaJSON === 'object' && mediaJSON.xml.result.error) {
              Ember.debug('Unable to create media entry for new video. Error Code:' + mediaJSON.xml.result.error.code + ' Error Message: ' + mediaJSON.xml.result.error.message);
              resolve(false);
            } else if (typeof mediaJSON === 'object' && mediaJSON.xml.result.objectType === 'KalturaMediaEntry') {
              let rootEntryId = mediaJSON.xml.result.rootEntryId;
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$.ajax({
                url: 'https://www.kaltura.com/api_v3/service/media/action/addContent',
                data: {
                  ks: this.kSession,
                  entryId: rootEntryId,
                  resource: {
                    objectType: 'KalturaUploadedFileTokenResource',
                    token: rToken.id
                  }
                },
                success: token => {
                  let tokenJSON = this.xmlToJson(token);
                  if (typeof tokenJSON === 'object' && tokenJSON.xml.result.error) {
                    Ember.debug('Unable to add video to media entry. Error Code:' + tokenJSON.xml.result.error.code + ' Error Message: ' + tokenJSON.xml.result.error.message);
                    resolve(false);
                  } else if (typeof tokenJSON === 'object' && tokenJSON.xml.result.objectType === 'KalturaMediaEntry') {
                    resolve(tokenJSON);
                  } else {
                    resolve(false);
                    Ember.debug('Kaltura returned malformed token');
                  }
                },
                error: error => {
                  Ember.debug('Kaltura media error: ' + error.responseText);
                  resolve(false);
                }
              });
            } else {
              Ember.debug('Unable to create media entry for uploaded video file');
            }
          },
          error: error => {
            Ember.debug('Kaltura session start error: ' + error.responseText);
            resolve(false);
          }
        });
      });
    },
    createToken: function () {
      return new Promise(resolve => {
        Ember.$.ajax({
          url: '/interface/kaltura-token-gen/',
          success: token => {
            resolve(token);
          },
          error: error => {
            Ember.debug('Kaltura token request error: ' + error.responseText);
            resolve(false);
          }
        });
      });
    },
    parseFile: function (file, callback) {
      let fileSize = file.size,
        chunkSize = 64 / Math.pow(1024, 2),
        // 64mb chunk size
        offset = 0,
        chunkReaderBlock = null;
      let readEventHandler = function (evt) {
        if (evt.target.error === null) {
          offset += evt.target.result.length;
          callback(evt.target.result); // callback for handling read chunk
        } else {
          Ember.debug('Read error: ' + evt.target.error);
          return;
        }
        if (offset >= fileSize) {
          Ember.debug('Done reading file');
          return;
        }

        // of to the next chunk
        chunkReaderBlock(offset, chunkSize, file);
      };
      chunkReaderBlock = function (_offset, length, _file) {
        var r = new FileReader();
        var blob = _file.slice(_offset, length + _offset);
        r.onload = readEventHandler;
        r.readAsText(blob);
      };

      // now let's start the read with the first block
      chunkReaderBlock(offset, chunkSize, file);
    },
    // Kaltura returns XML - Convert to obj for ease of use
    xmlToJson: function (xml) {
      // Create the return object
      let obj = {};
      if (xml.nodeType == 1) {
        // element
        // do attributes
        if (xml.attributes.length > 0) {
          obj['@attributes'] = {};
          for (let j = 0; j < xml.attributes.length; j++) {
            let attribute = xml.attributes.item(j);
            obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType == 3) {
        // text
        obj = xml.nodeValue;
      }

      // do children
      if (xml.hasChildNodes() && xml.childNodes[0].nodeName !== '#text') {
        for (let i = 0; i < xml.childNodes.length; i++) {
          let item = xml.childNodes.item(i);
          let nodeName = item.nodeName;
          if (typeof obj[nodeName] == 'undefined') {
            obj[nodeName] = this.xmlToJson(item);
          } else {
            if (typeof obj[nodeName].push == 'undefined') {
              let old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }
            obj[nodeName].push(this.xmlToJson(item));
          }
        }
      } else if (xml.hasChildNodes() && xml.childNodes[0].nodeName === '#text') {
        obj = xml.childNodes[0].nodeValue;
      }
      return obj;
    }
  });
});