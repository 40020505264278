define("bocce/mixins/editable", ["exports", "bocce/mixins/embed-parser", "bocce/helpers/upload", "sanitize-html", "bocce/utilities/dialog"], function (_exports, _embedParser, _upload, _sanitizeHtml, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */

  // html sanitizer

  function sanitize(string) {
    const sanitizeHtmlOptions = {
      // options for the sanitize-html package. see docs:
      // https://www.npmjs.com/package/sanitize-html#htmlparser2-options
      allowedTags: _sanitizeHtml.default.defaults.allowedTags.concat(['h2', 'u']),
      transformTags: {
        'h1': 'h2',
        // Word's header is h1, but our header is h2
        'a': function (tagName, attribs) {
          // filter out useless links because apparently the geniuses
          // at Microsoft decided to start including THOSE now
          if (!attribs['href'] || attribs['href'] === '') {
            return false;
          } else {
            return {
              tagName: tagName,
              attribs: {
                href: attribs.href
              }
            };
          }
        }
      }
    };
    return (0, _sanitizeHtml.default)(string, sanitizeHtmlOptions);
  }

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create(_embedParser.default, {
    gainsight: Ember.inject.service(),
    inEditor: false,
    bodyInput: '',
    replyText: '',
    replyAuthor: '',
    file_ids: [],
    encoding_videos: Ember.A([]),
    replyId: false,
    postable: Ember.computed('bodyInputHasContent', 'attachmentsUploading', 'model', function () {
      let bodyInput = this.bodyInputHasContent;
      return bodyInput && !this.attachmentsUploading;
    }),
    mobileUpload: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$.isMobile;
    }),
    totalFiles: Ember.computed('files.@each.deleted', function () {
      var length = this.files.filterBy('deleted', false).filterBy('valid', true).length;
      if (length === 0) {
        length = false;
      }
      return length;
    }),
    encodingVideosToContainer: Ember.computed('encoding_videos.[]', function () {
      return this.encoding_videos.slice(0).map(obj => {
        return {
          ...obj,
          file: {
            type: "video"
          }
        };
      });
    }),
    videoEmbedString: Ember.computed('files.@each.deleted', function () {
      var embeds = this.files.filterBy('deleted', false).filterBy('valid', true),
        string = '',
        i;
      for (i = 0; i < embeds.length; i++) {
        if (embeds[i].file.isUrl) {
          string += '<div class=\'video-container\'>';
          string += '<video class=\'post-video-embed\' preload=\'none\' controls>';
          string += '<source src=\'' + embeds[i].file.url + '\' type=\'' + embeds[i].file.type + '\'>';
          string += '</video>';
          string += '<div class="sub-time"><i class="far fa-clock"></i> ' + embeds[i].file.created_at_formatted + '</div>';
          string += '</div>';
        }
      }
      return string;
    }),
    localArchive: Ember.computed('files.[]', function () {
      var array, obj, faved, unfaved;
      if (!localStorage.localArchive || localStorage.localArchive.length === 0) {
        return [];
      }
      try {
        obj = JSON.parse(localStorage.localArchive);
      } catch (err) {
        obj = {};
      }

      /* eslint-disable-next-line ember/no-jquery */
      array = Ember.$.map(obj, function (value) {
        return [value];
      });
      array.reverse();

      /* eslint-disable-next-line ember/no-jquery */
      faved = Ember.$.grep(array, function (e) {
        return e.isFaved === true;
      });
      /* eslint-disable-next-line ember/no-jquery */
      unfaved = Ember.$.grep(array, function (e) {
        return e.isFaved !== true;
      });
      array = faved.concat(unfaved);
      return array;
    }),
    localDocs: Ember.computed(function () {
      var array, obj, faved, unfaved;
      if (!localStorage.localDocs || localStorage.localDocs.length === 0) {
        return [];
      }
      try {
        obj = JSON.parse(localStorage.localDocs);
      } catch (err) {
        obj = {};
      }

      /* eslint-disable-next-line ember/no-jquery */
      array = Ember.$.map(obj, function (value) {
        return [value];
      });
      array.reverse();

      /* eslint-disable-next-line ember/no-jquery */
      array = Ember.$.grep(array, function (e) {
        return e.name !== 'Auto Save';
      });

      /* eslint-disable-next-line ember/no-jquery */
      faved = Ember.$.grep(array, function (e) {
        return e.isFaved === true;
      });
      /* eslint-disable-next-line ember/no-jquery */
      unfaved = Ember.$.grep(array, function (e) {
        return e.isFaved !== true;
      });
      array = faved.concat(unfaved);
      return array;
    }),
    currentModel: Ember.computed.reads('model'),
    working: Ember.computed('files.@each.uploaded', function () {
      return this.files.filterBy('uploaded', false).length > 0;
    }),
    /* eslint-disable-next-line ember/no-observers */
    destroyer: Ember.observer('model', function () {
      if (!this.model) {
        if (this.files) {
          this.send('clearAllFiles');
        }
        this.send('destroyEditor');
      }
    }),
    // Grabs up files and uploads them immediately
    // Shoves returned ID into the fileIDs array
    /* eslint-disable-next-line ember/no-observers */
    uploadFiles: Ember.observer('files.[]', function () {
      let files = this.files;
      if (!this.store) {
        return;
      }
      for (let f of files) {
        this.uploadOneFile(f);
      }
    }),
    uploadOneFile(f) {
      if (!f || f.uploading || f.uploaded) {
        return;
      }
      let ftype = f.file.type.split('/')[0];
      f.uploaded_name = f.uploaded_name || f.file.name;
      let videoUploadCheck = Promise.resolve('Continue uploading');
      if (ftype === 'video' && f.file.type !== 'video/mp4' && !f.ignoreDownloadOnlyPrompt) {
        videoUploadCheck = (0, _dialog.default)('This file will be available for download only. Continue uploading or upload via video tool?', ['Continue uploading', 'Use video tool']);
      } else {
        videoUploadCheck = Promise.resolve('Continue uploading');
      }
      videoUploadCheck.then(choice => {
        if (choice === 'Use video tool') {
          this.send('toggleCameraPanel', false);
          this.files.removeObject(f);
        } else if (choice === 'Continue uploading') {
          if (!f.uploading && f.valid) {
            Ember.debug('Uploading ' + f.file.name);
            Ember.set(f, 'uploading', true);
            if (f.file.isUrl) {
              // File is a CameraTag Embed
              Ember.set(f, 'uploading', false);
              Ember.set(f, 'uploaded', true);
              return;
            }
            (0, _upload.default)(f.file, this.session.get('user.id'), this.store, /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.existing-conversation').hasClass('active') ? 'conversation attachments' : null, null, progress => {
              Ember.set(f, 'percent_uploaded', progress);
            }).then(att_id => {
              return this.store.findRecord('attachment', att_id).then(() => {
                if (f && this.files.findBy('file', f.file)) {
                  let ids = this.file_ids;
                  ids.push({
                    id: att_id
                  });
                  this.set('file_ids', ids);
                  Ember.set(f, 'uploading', false);
                  Ember.set(f, 'uploaded', true);
                  Ember.set(f, 'uploaded_id', att_id);
                }
              });
            }, function (err) {
              Ember.debug(err, 'Could not upload file');
              alert(`"${f.file.name}" failed to upload.  Please retry.`);
              this.files.removeObject(f);
            }.bind(f));
          }
        }
      });
    },
    attachmentsUploading: Ember.computed('files.[]', 'files.@each.uploaded', 'encoding_videos.[]', function () {
      /**
       * Return true if anything is currently uploading.
       */

      const uploadingFiles = this.files ? !!this.files.findBy('uploaded', false) : false;

      //We add to encoding_videos before uploading to Kaltura for instance, and we remove from it after the upload is done. So,
      //if there is anything in encoding_videos, this means that something is uploading.
      const videosAreEncoding = Array.isArray(this.encoding_videos) && this.encoding_videos.length;
      return uploadingFiles || videosAreEncoding;
    }),
    urlize: function (str) {
      return str.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]+/g, '_').toLowerCase();
    },
    bodyInputHasContent: Ember.computed('bodyInput', function () {
      // NK note to future self: Needing to handle all this at this stage
      // is ugly, but it gets the job done. A friendlier interface will
      // be to abstract away the sanitization/cleanup code within an
      // rte-editor Component, so that the Controller can just interact
      // with the bodyInput value without needing to do too much cleanup.

      const bodyInput = this.bodyInput || '';
      // cut out HTML
      let cleaned = sanitize(bodyInput);
      // remove line breaks
      cleaned = cleaned.replace(/<br( \/)?>/g, '');
      // Remove the comment block that the browser puts in.
      cleaned = cleaned.replace(/<!---->/g, '');
      // Remove spaces
      cleaned = cleaned.replace(/\s/g, '');
      return cleaned.length > 0;
    }),
    hasNonDeletedAttachments: Ember.computed('files.@each.deleted', function () {
      const files = this.files || [];
      const hasNonDeleted = Boolean(files.find(file => file.valid && !file.deleted));
      return hasNonDeleted;
    }),
    editorHasContent: Ember.computed('bodyInputHasContent', 'hasNonDeletedAttachments', function () {
      const hasContent = this.bodyInputHasContent;
      const hasAttachments = this.hasNonDeletedAttachments;
      return hasContent || hasAttachments;
    }),
    actions: {
      promptAsRecovery: function (save) {
        (0, _dialog.default)('Looks like you closed your tab (or the application crashed) while you were writing something. Would you like to recover it?', ['Yes', 'No']).then(choice => {
          if (choice === 'Yes') {
            this.set('assetsRecovered', true);
            this.send('useArchivedText', save, true);
            this.send('deleteArchivedText', save);
          } else {
            this.set('assetsRecovered', true);
            this.send('deleteArchivedText', save);
          }
        });
      },
      editPost: function (post_id) {
        let pid = post_id ? '#discussion-response-' + post_id + ' .discussion-reply .discussion-reply-body' : '.first-post-body',
          /* eslint-disable-next-line ember/no-jquery */
          $bod = Ember.$('.floating-modal.active .modal-content ' + pid),
          /* eslint-disable-next-line ember/no-jquery */
          title = Ember.$('.floating-modal.active .modal-content .subject .discussion-title').html() || '',
          div = document.createElement('div'),
          vidString = '',
          whitelist = 'a, br, b, u, h2, ul, ol, li, p',
          vid,
          replyText,
          replyId,
          replyUser,
          i,
          reply,
          body;
        div.innerHTML = $bod.html();
        vid = div.getElementsByClassName('video-container');
        this.set('response_video', '');

        // Remove video embeds and stow them away to be added back
        // When reply edit is posted
        if (vid.length > 0) {
          for (i = 0; i < vid.length; i++) {
            vidString += '<div class="video-container">' + vid[i].innerHTML + '</div>';
          }
          this.set('response_video', vidString);
          for (i = 0; i < vid.length; i++) {
            vid[i].remove();
          }
        }
        if (post_id) {
          // Clean out reply, if there is one
          // Pull out reply elements
          reply = div.getElementsByClassName('is-reply');
          if (reply.length > 0) {
            replyText = reply[0].getElementsByClassName('is-reply-text')[0].innerText;
            replyId = reply[0].getElementsByClassName('is-reply-id')[0].innerText;
            replyUser = reply[0].getElementsByClassName('is-reply-user')[0].innerText;
            this.send('replyToDiscussion', {
              id: replyId,
              user: replyUser,
              text: replyText
            });
            reply[0].remove();
          }
          this.set('reply_id', post_id);
          this.set('is_reply', true);
        } else {
          this.set('titleInput', title.trim());
        }

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(div).find('iframe').remove();
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(div).find('*').not(whitelist).each(function () {
          /* eslint-disable-next-line ember/no-jquery */
          var content = Ember.$(this).contents();
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$(this).replaceWith(content);
        });
        body = div.innerHTML.replace(/<a .*?href="(.*?)".*?>.*?<\/a>/gi, '$1');
        this.set('is_editing', true);
        this.set('bodyInput', body);
      },
      deletePost: function (post_id) {
        const self = this;
        (0, _dialog.default)(`Are you sure you wish to delete this discussion${post_id ? ' post' : ''}? This action CANNOT be undone!`, [`Yes${post_id ? '. If permanent deletion of attachments is desired, first delete manually in S3. Then manually delete in Canvas.' : ''}`, 'No']).then(choice => {
          if (choice.indexOf('Yes') === 0) {
            let model = this.model;
            if (post_id) {
              this.store.nestResources('response', [{
                section: model.get('section').get('id')
              }, {
                discussion: model.get('id')
              }]);
              this.store.findRecord('response', post_id).then(record => {
                let childResponses = self.childResponses ? self.childResponses[post_id] : null;
                try {
                  record.destroyRecord().then(() => {
                    if (childResponses && childResponses.length > 0) {
                      let promises = [];
                      childResponses.forEach(childResponse => {
                        promises.push(self.store.findRecord('response', childResponse.get('id')).then(record => {
                          record.destroyRecord();
                        }));
                      });
                      try {
                        Promise.all(promises).catch(e => {
                          (0, _dialog.default)('There was a problem deleting replies to the discussion post. These must now be manually deleted.');
                        });
                      } catch (e) {
                        (0, _dialog.default)('There was a problem deleting replies to the discussion post. These must now be manually deleted.');
                      }
                    }
                  }).catch(e => {
                    (0, _dialog.default)('There was a problem deleting the discussion post. Please reload the page and try again.');
                  });
                } catch (e) {
                  (0, _dialog.default)('There was a problem deleting the discussion post. Please reload the page and try again.');
                }
              });
            } else {
              model.destroyRecord().then(() => {
                let discussionsCtl = this.discussions;
                if (discussionsCtl.send) {
                  discussionsCtl.send('closeModals', true);
                }
              });
            }
          }
        });
      },
      useArchivedText: function (file, recovery) {
        /* eslint-disable-next-line ember/no-jquery */
        var repBox = Ember.$('.floating-modal.active .rte-editor-input');
        repBox.html(repBox.html() + file.textContent);
        if (!recovery) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.video-archive').removeClass('visible');
        }
      },
      favoriteText: function (file) {
        let fl;
        try {
          fl = JSON.parse(localStorage.localDocs);
        } catch (err) {
          fl = {};
        }
        fl[file.name].isFaved = true;
        localStorage.localDocs = JSON.stringify(fl);
        Ember.notifyPropertyChange(this, 'localDocs');
      },
      unFavoriteText: function (file) {
        let fl;
        try {
          fl = JSON.parse(localStorage.localDocs);
        } catch (err) {
          fl = {};
        }
        fl[file.name].isFaved = false;
        localStorage.localDocs = JSON.stringify(fl);
        Ember.notifyPropertyChange(this, 'localDocs');
      },
      deleteArchivedText: function (file) {
        let fl;
        try {
          fl = JSON.parse(localStorage.localDocs);
        } catch (err) {
          fl = {};
        }
        let confirmation;
        if (file.name === 'Auto Save') {
          confirmation = Promise.resolve('Yes');
        } else {
          confirmation = (0, _dialog.default)('Are you sure you want to delete this file from your archive? This cannot be undone.', ['Yes', 'No']);
        }
        confirmation.then(choice => {
          if (choice === 'Yes') {
            if (!file.recName) {
              // checks if there's a recName recorded on the file name.
              // old version of the code didn't include the date in the recName
              for (let fileName in fl) {
                if (fl[fileName].name === file.name) {
                  file.recName = fileName;
                }
              }
            }
            delete fl[file.recName];
            if (localStorage.localDocRunner === file.name) {
              delete localStorage.localDocRunner;
            }
            localStorage.localDocs = JSON.stringify(fl);
            Ember.notifyPropertyChange(this, 'localDocs');
          }
        });
      },
      previewArchivedText: function (file) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.popup-container').addClass('active');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.popup-container .content').html(file.textContent);
      },
      toggleLocalArchive: function () {
        this.send('updateLocalDocs');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.video-archive').toggleClass('visible');
      },
      updateLocalDocs() {
        this.notifyPropertyChange('localDocs');
      },
      cancelUpload: function (file) {
        (0, _dialog.default)('Are you sure you want to cancel the file upload?', ['Yes', 'No']).then(choice => {
          if (choice === 'Yes') {
            this.files.removeObject(file);
          }
        });
      },
      editFileName: function (file) {
        let identifier = !file.file.isUrl ? file.uploaded_id : file.file.name;
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(`.attachments .file-name.file-id-${identifier}`).addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(`.attachments .file-name-input.file-id-${identifier}`).removeClass('hidden');
      },
      editFileNameConfirm: function (file, fileExtension) {
        let identifier = file.uploaded_id,
          /* eslint-disable-next-line ember/no-jquery */
          newName = Ember.$(`.attachments .file-name-input.file-id-${identifier} input`).val();
        if (!newName || newName.includes('.')) {
          alert('Invalid file name, must not contain "."');
          return;
        }
        newName = newName + fileExtension;
        Ember.set(file, 'uploaded_name', newName);
        this.store.findRecord('attachment', file.uploaded_id).then(loadedAttachment => {
          loadedAttachment.set('name', newName);
          loadedAttachment.save();
        });

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(`.attachments .file-name.file-id-${identifier}`).removeClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(`.attachments .file-name-input.file-id-${identifier}`).addClass('hidden');
      },
      destroyEditor: function () {
        this.set('bodyInput', '');
        if (this.titleInput) {
          this.set('titleInput', '');
        }
      },
      // save response from editor if save=true.
      // change styling so that editor is not visible.
      doneEditing: function (save) {
        var content = this.bodyInput,
          currentModel = this.currentModel,
          currentModelType = currentModel.constructor.modelName;

        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$.trim(content.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length > 0) {
          if (save) {
            if (currentModelType !== 'conversation') {
              if (this.is_editing) {
                this.send('submitEdit', content);
              } else {
                this.send('submitResponse', content);
              }
              if (this.repBox) {
                this.repBox.html('');
              }
              this.set('titleInput', '');
              this.set('bodyInput', '');
              this.set('replyId', false);
              this.set('replyText', '');
              this.set('replyAuthor', '');
            }

            // Gainsight
            let course = this.session.course,
              user = this.session.user,
              workType = currentModel && currentModel.title ? `${currentModel.title.split(" ")[0]} (i.e., title does NOT start with Discussion, Assignment, or Exercise)` : 'Unknown';
            if (currentModel.title.startsWith('Discussion')) {
              workType = "Discussion";
            } else if (currentModel.title.startsWith('Assignment')) {
              workType = "Assignment";
            } else if (currentModel.title.startsWith('Exercise')) {
              workType = "Exercise";
            }
            this.gainsight.renderDiscussionGainsightPxTag(new Date());
            this.gainsight.renderWorkGainsightPxTag(user.get('id'), course.get('id'), workType, new Date());
          } else {
            this.send('clearAllFiles');
            this.set('inEditor', false);
            this.set('titleInput', '');
            this.set('is_editing', false);
            this.send('destroyEditor');
            this.set('replyId', false);
            this.set('replyText', '');
            this.set('replyAuthor', '');
            this.set('reply_id', false);
            this.set('is_reply', false);
            this.set('response_video', false);
          }
        }
      },
      // user submits response to discussion
      // this occurs through either the regular input form or through
      // the wysiwyg editor
      submitResponse: function (content) {
        var currMod = this.currentModel,
          date = new Date(),
          that = this,
          replyId = this.replyId,
          replyText = this.replyText,
          replyAuthor = this.replyAuthor,
          replyBk,
          resp,
          usr,
          discId;
        if (content) {
          content = this.parseEmbed(content);
        }
        if (replyId) {
          replyBk = '<div class="is-reply"><div class="is-reply-id">' + replyId + '</div><div class="is-reply-user">' + replyAuthor + '</div><div class="is-reply-text">' + replyText + '</div></div>';
          content = replyBk + content;
        }
        if (content.length > 64000) {
          (0, _dialog.default)('Your post exceeds the body size limit by ' + (content.length - 64000) + ' characters. Please shorten it and try posting again.');
          return;
        }

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#pop-attachment-drawer-handle').prop('checked', false);
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.pop-attachment-drawer').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.floating-modal.active').removeClass('drawer-open');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.camera-container').removeClass('active');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.audio-rec-panel').removeClass('active');
        if (!currMod) {
          discId = '' + this.model.item.get('data').id + '';
          currMod = this.discussions.model.content.findBy('id', discId);
        }
        if (currMod && currMod.get) {
          /* eslint-disable-next-line ember/no-get */
          if (this.get('model.conversationPartner.name')) {
            this.store.nestResources('response', [{
              section: currMod.get('section').get('id')
            }, {
              conversation: currMod.get('id')
            }]);
          } else {
            this.store.nestResources('response', [{
              section: currMod.get('section').get('id')
            }, {
              discussion: currMod.get('id')
            }]);
          }
          if (!content) {
            return false;
          }
          if (content.trim() !== '') {
            usr = this.session.get('user');

            /* eslint-disable-next-line ember/no-get */
            if (this.get('model.conversationPartner.name')) {
              resp = this.store.createRecord('response', {
                user: usr,
                conversation: currMod
              });
            } else {
              resp = this.store.createRecord('response', {
                user: usr,
                discussion: currMod,
                parent: replyId || null
              });
            }

            // Add any video embeds
            content = content + this.videoEmbedString;
            resp.set('message', content);
            resp.set('date', date);
            /* eslint-disable-next-line ember/no-get */
            this.get('currentModel.responses').pushObject(resp);
            that.set('inEditor', false);
            currMod.set('i_submitted', true);
            /* eslint-disable-next-line ember/no-get */
            if (!this.working && this.get('file_ids.length') > 0) {
              resp.set('attachments', that.get('file_ids'));
            }
            resp.save().then(function () {
              that.send('clearAllFiles');
            }, function () {
              var conv;
              that.send('clearAllFiles');
              conv = that.get('currentModel.responses').objectAt(that.get('currentModel.responses.length') - 1);
              conv.set('needs_repost', true);
              if (that.get('currentModel')) {
                that.get('currentModel').set('failed_count', that.get('currentModel.failed_count') ? that.get('currentModel.failed_count') + 1 : 1);
              }
            });
          }
          return false;
        }
      },
      repostResponse: function (resp) {
        var that = this;
        resp.set('needs_repost', false);
        resp.set('is_reposting', true);
        resp.save().then(function () {
          resp.set('is_reposting', false);
          if (that.get('currentModel')) {
            that.get('currentModel').set('failed_count', that.get('currentModel.failed_count') === 1 ? false : that.get('currentModel.failed_count') - 1);
          }
        }, function () {
          resp.set('needs_repost', true);
          resp.set('is_reposting', false);
        });
      },
      // user submits response to discussion
      // this occurs through either the regular input form or through
      // the wysiwyg editor
      submitEdit: function (content) {
        var currMod = this.currentModel,
          title = this.is_reply ? false : this.titleInput,
          message = this.parseEmbed(content),
          that = this,
          replyId,
          replyAuthor,
          replyText,
          replyBk;

        // Add any video embeds
        message = message + this.response_video + this.videoEmbedString;
        this.set('uploadInProgress', true);
        this.set('replyInProgress', true);
        if (this.is_reply) {
          // Tack reply contents, if this is a reply
          replyId = this.replyId;
          if (replyId) {
            replyText = this.replyText;
            replyAuthor = this.replyAuthor;
            replyBk = '<div class="is-reply"><div class="is-reply-id">' + replyId + '</div><div class="is-reply-user">' + replyAuthor + '</div><div class="is-reply-text">' + replyText + '</div></div>';
            message = replyBk + message;
          }
          this.store.nestResources('response', [{
            section: currMod.get('section').get('id')
          }, {
            discussion: currMod.get('id')
          }]);
          this.store.findRecord('response', this.reply_id).then(function (record) {
            record.set('message', message);
            record.save().then(function () {
              that.set('uploadInProgress', false);
              that.set('replyInProgress', false);
              that.set('is_editing', false);
              that.set('is_reply', false);
              that.set('reply_id', false);
              that.set('response_video', false);
              that.set('titleInput', '');
              that.send('clearAllFiles');
              that.send('destroyEditor');
            });
          });
        } else {
          if (title) {
            currMod.set('title', title);
          }
          currMod.set('message', message);
          currMod.save().then(() => {
            this.set('uploadInProgress', false);
            this.set('is_editing', false);
            this.set('titleInput', '');
            this.send('clearAllFiles');
            this.send('destroyEditor');
            this.discussions.send('closeModals', true);
          });
        }
      }
    }
  });
});