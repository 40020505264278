define("bocce/mixins/interactions/drag_and_drop_quiz", ["exports", "bocce/mixins/support/render-template", "bocce/mixins/support/util"], function (_exports, _renderTemplate, util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function DragAndDropQuiz($el, data) {
    // bind all methods to this
    util.bindAll(this);
    var that = this,
      i,
      j;

    // class vars
    this.$el = $el;
    this.$interactionData = this.$el.find('.interaction_data');
    this.$containerEl = this.$el.find('.interaction_content');
    this.context = JSON.parse(data.initial_json);
    if (this.context.signalFlowOpts && !!this.context.signalFlowOpts.connectors[0]) {
      this.context.currentColor = this.context.signalFlowOpts.connectors[0].color;
    }
    this.context.config = data.config;
    this.context.rolloverPreview = this.context.config.rolloverPreview === 'true';
    this.assets = data.assets;
    this.$el.find('.interaction_content').css('position', 'relative');
    this.context.intId = this.$el.attr('id');
    this.context.getOffset = function (el, cont) {
      return {
        /* eslint-disable-next-line ember/no-jquery */
        left: Ember.$(el).offset().left - Ember.$(cont).offset().left + 5,
        /* eslint-disable-next-line ember/no-jquery */
        top: Ember.$(el).offset().top - Ember.$(cont).offset().top + 5
      };
    };
    this.context.drawLine = function (div1, div2, color, thickness, correct, $el) {
      var off1 = this.getOffset(div1, $el),
        off2 = this.getOffset(div2, $el),
        // calc bott
        x1 = off1.left,
        y1 = off1.top,
        // calc top
        x2 = off2.left,
        y2 = off2.top,
        // distance
        length = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1)),
        // center
        cx = (x1 + x2) / 2 - length / 2,
        cy = (y1 + y2) / 2 - thickness / 2,
        // angle
        angle = Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI),
        // is the question correct?
        isWrong = correct ? '' : 'wrong',
        // draw line
        htmlLine = '<div class="connection' + isWrong + '" style="padding: 0px; z-index: 100; border-radius:' + thickness + 'px; margin:0px; height:' + thickness + 'px; background-color:' + color + '; line-height: 1px; position:absolute; left:' + cx + 'px; top:' + cy + 'px; width:' + length + 'px; -moz-transform:rotate(' + angle + 'deg); -webkit-transform:rotate(' + angle + 'deg); -o-transform:rotate(' + angle + 'deg); -ms-transform:rotate(' + angle + 'deg); transform:rotate(' + angle + 'deg);" />';
      $el.append(htmlLine);
    };

    // Turn CSS object into inline CSS string
    this.context.cssify = function (ctxt) {
      var styleString = 'position: absolute; ',
        key,
        suffix;
      if (ctxt.backgroundImage) {
        styleString += 'background-image: url("' + that.assets.images[parseInt(ctxt.backgroundImage)] + '"); background-repeat: no-repeat; ';
      }
      delete ctxt.css['border'];
      delete ctxt.css['border-radius'];
      delete ctxt.css['box-shadow'];
      delete ctxt.css['padding'];
      delete ctxt.css['background-repeat'];
      delete ctxt.css['background-position'];
      for (key in ctxt.css) {
        if (key === 'width' || key === 'height') {
          suffix = 'px';
        } else {
          suffix = '';
        }
        styleString += key + ': ' + ctxt.css[key] + suffix + '; ';
      }
      return styleString;
    };
    for (i = 0; i < this.context.draggables.length; i++) {
      this.context.draggables[i].uniqueSuffix = this.context.draggables[i].css.left + '-' + this.context.draggables[i].css.top;
      this.context.draggables[i].css = this.context.cssify(this.context.draggables[i]);
      if (this.context.draggables[i].labelAlign === 'bottom' || this.context.draggables[i].labelAlign === 'top') {
        this.context.draggables[i].labelAndImage = true;
      }
      if (this.context.draggables[i].endpoints) {
        this.context.draggables[i].static = true;
        for (j = 0; j < this.context.draggables[i].endpoints.length; j++) {
          this.context.draggables[i].endpoints[j].css = this.context.cssify(this.context.draggables[i].endpoints[j]);
        }
      }
    }
    for (i = 0; i < this.context.dropZones.length; i++) {
      if (this.context.dropZones[i].labelAlign === 'bottom' || this.context.dropZones[i].labelAlign === 'top') {
        this.context.dropZones[i].labelAndImage = true;
      }
      this.context.dropZones[i].css = this.context.cssify(this.context.dropZones[i]);
    }
    for (i = 0; i < this.context.images.length; i++) {
      this.context.images[i].css = this.context.cssify(this.context.images[i]);
      this.context.images[i].src = this.assets.images[parseInt(this.context.images[i].backgroundImage)];
      if (this.context.images[i].endpoints) {
        this.context.images[i].static = true;
        for (j = 0; j < this.context.images[i].endpoints.length; j++) {
          this.context.images[i].endpoints[j].css = this.context.cssify(this.context.images[i].endpoints[j]);
        }
      }
    }

    // If statement because markers were added in v2; not all drag and drops have markers.
    if (Object.prototype.hasOwnProperty.call(this.context, 'markers') === true) {
      for (i = 0; i < this.context.markers.length; i++) {
        this.context.markers[i].css = this.context.cssify(this.context.markers[i]);
      }
    }
  }
  DragAndDropQuiz.prototype = {
    init: function () {
      // add UI
      return (0, _renderTemplate.default)('drag_and_drop_quiz', 'main', this.context).then(content => {
        this.$containerEl.addClass('scrollbar-mobile').append(content);
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          this.initListenersMobile();
        } else {
          this.initListeners();
        }
      });
    },
    initListeners: function () {
      var that = this,
        pal = this.$el.find('.signal-flow-palette .swatch');
      if (pal.length === 1) {
        if (pal.attr('data-type') === 'Default') {
          this.$el.find('.signal-flow-palette').remove();
        }
      }
      this.$el.find('.endpoint').on('drop', function (event) {
        var data = event.originalEvent.dataTransfer.getData('text'),
          elem = document.getElementById(data),
          correctArray = elem.attributes['validConnections'].value.split(' '),
          correct = false,
          i;
        if (elem.attributes['correctColor'].value === that.context.currentColor) {
          for (i = 0; i < correctArray.length; i++) {
            if (that.context.intId + '-endpoint-' + correctArray[i] === this.id) {
              correct = true;
              break;
            }
          }
        }
        event.preventDefault();

        /* eslint-disable-next-line ember/no-jquery */
        that.context.drawLine(document.getElementById(this.id), elem, that.context.currentColor, 3, correct, Ember.$('#drag-and-drop-' + that.context.intId));
      });
      this.$el.find('.drop-zone').on('drop', function (event) {
        var data = event.originalEvent.dataTransfer.getData('text'),
          /* eslint-disable-next-line ember/no-jquery */
          aud = Ember.$('#audio-' + that.context.intId),
          elem = document.getElementById(data),
          remaining,
          assetValue,
          audSrc,
          feedback;
        event.preventDefault();

        // Check for drop zone match
        if (this.id === elem.attributes['dropZone'].value) {
          elem.classList.add('placed');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$(event.target).closest('.drop-zone')[0].appendChild(elem);
          feedback = unescape(elem.attributes['feedbackCorrect'].value);
          if (feedback === '') {
            // Default Feedback
            feedback = 'Correct!';
          } else {
            /* eslint-disable-next-line ember/no-jquery */
            remaining = Ember.$('#drag-and-drop-' + that.context.intId + ' > .draggable.placeable').length;
            if (remaining !== 0) {
              feedback = feedback.replace('{NUM_REMAINING}', remaining + ' left to go.');
            } else {
              // Default Feedback
              feedback = 'Good work!';
            }
          }
          assetValue = elem.attributes['soundCorrect'];
          if (assetValue) {
            audSrc = that.assets.sounds[parseInt(assetValue.value)];
          }
          if (audSrc && aud.length > 0) {
            aud.attr('src', audSrc);
            aud[0].load();
            aud[0].play();
          }

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#feedback-mask-' + that.context.intId).addClass('active');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#feedback-' + that.context.intId).html(feedback);
        } else {
          feedback = unescape(elem.attributes['feedbackIncorrect'].value);
          if (feedback === '') {
            // Default Feedback
            feedback = 'Try again!';
          }
          assetValue = elem.attributes['soundIncorrect'];
          if (assetValue) {
            audSrc = that.assets.sounds[parseInt(assetValue.value)];
          }
          if (audSrc && aud.length > 0) {
            aud.attr('src', audSrc);
            aud[0].load();
            aud[0].play();
          }

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#feedback-mask-' + that.context.intId).addClass('active');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#feedback-' + that.context.intId).html(feedback);
        }
      });

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.drop-zone').on('dragover', function (event) {
        event.preventDefault();
      });

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.endpoint').on('dragover', function (event) {
        event.preventDefault();
      });
      this.$el.find('.connector').on('dragstart', function (event) {
        event.originalEvent.dataTransfer.setData('text', event.target.id);
      });
      this.$el.find('.draggable').on('dragstart', function (event) {
        event.originalEvent.dataTransfer.setData('text', event.target.id);
      });
      this.$el.find('.draggable').on('mouseover', function (event) {
        /* eslint-disable-next-line ember/no-jquery */
        var aud = Ember.$('#audio-' + that.context.intId),
          assetValue = this.attributes['soundOver'],
          /* eslint-disable-next-line ember/no-jquery */
          bri = Ember.$(this).css('background-image'),
          audSrc,
          prev;
        if (bri) {
          prev = that.$el.find('.rollover-preview-image');
          if (prev.length > 0) {
            prev.attr('src', bri.replace(/.*\s?url\(['"]?/, '').replace(/['"]?\).*/, ''));
          }
        }
        if (this !== event.target) {
          event.stopPropagation();
        }
        if (assetValue) {
          audSrc = that.assets.sounds[parseInt(assetValue.value)];
        }
        if (audSrc && aud.length > 0) {
          aud.attr('src', audSrc);
          aud[0].load();
          aud[0].play();
        }
      });
      this.$el.find('.draggable').on('mouseleave', function (event) {
        /* eslint-disable-next-line ember/no-jquery */
        var aud = Ember.$('#audio-' + that.context.intId),
          prev = that.$el.find('.rollover-preview-image');
        if (prev.length > 0) {
          prev.attr('src', '');
        }
        if (this !== event.target) {
          event.stopPropagation();
        }
        if (aud.length > 0) {
          aud[0].pause();
        }
      });
      this.$el.find('.draggable').on('click', function (event) {
        /* eslint-disable-next-line ember/no-jquery */
        var aud = Ember.$('#audio-' + that.context.intId),
          assetValue = this.attributes['soundClick'],
          audSrc;
        if (this !== event.target) {
          event.stopPropagation();
        }
        if (assetValue) {
          audSrc = that.assets.sounds[parseInt(assetValue.value)];
        }
        if (audSrc && aud.length > 0) {
          aud.attr('src', audSrc);
          aud[0].load();
          aud[0].play();
        }
      });
      this.$el.on('dblclick', '.connection', function () {
        var con = confirm('Are you sure you want to delete this connector?');
        if (con) {
          this.remove();
        }
      });
      this.$el.find('.connection-checker').on('click', function () {
        /* eslint-disable-next-line ember/no-jquery */
        var $incorrect = Ember.$('#drag-and-drop-' + that.context.intId + ' .connection.wrong');
        $incorrect.addClass('wrong-highlight');
      });
      this.$el.find('.swatch').on('click', function () {
        that.context.currentColor = this.attributes['data-color'].value;
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#drag-and-drop-' + that.context.intId + ' .swatch').removeClass('current');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(this).addClass('current');
      });

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('#feedback-mask-' + that.context.intId).click(function () {
        this.classList.remove('active');
      });
    },
    initListenersMobile: function () {
      var that = this;

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('#drag-and-drop-' + that.context.intId).addClass('mobile');
      this.$el.find('.drop-zone').on('click', function (event) {
        /* eslint-disable-next-line ember/no-jquery */
        var $selected = Ember.$('#drag-and-drop-' + that.context.intId + ' .draggable.selected'),
          remaining,
          feedback;
        if ($selected.length === 0) {
          return;
        }
        if ($selected.attr('dropZone') === this.id) {
          // Absorb draggable
          event.target.appendChild($selected[0]);
          feedback = unescape($selected.attr('feedbackCorrect'));
          if (feedback === '') {
            // Default Feedback
            feedback = 'Correct!';
          } else {
            /* eslint-disable-next-line ember/no-jquery */
            remaining = Ember.$('#drag-and-drop-' + that.context.intId + ' > .draggable.placeable').length;
            if (remaining !== 0) {
              feedback = feedback.replace('{NUM_REMAINING}', remaining + ' left to go.');
            } else {
              // Default Feedback
              feedback = 'Good work!';
            }
          }
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#feedback-mask-' + that.context.intId).addClass('active');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#feedback-' + that.context.intId).html(feedback);
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#drag-and-drop-' + that.context.intId).removeClass('selected');
        } else {
          feedback = unescape($selected.attr('feedbackIncorrect'));
          if (feedback === '') {
            // Default Feedback
            feedback = 'Try again!';
          }
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#feedback-mask-' + that.context.intId).addClass('active');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#feedback-' + that.context.intId).html(feedback);
        }
      });
      this.$el.find('.draggable').on('click', function (event) {
        /* eslint-disable-next-line ember/no-jquery */
        var aud = Ember.$('#audio-' + that.context.intId),
          prev = that.$el.find('.rollover-preview-image'),
          /* eslint-disable-next-line ember/no-jquery */
          bri = Ember.$(this).css('background-image'),
          audSrc;
        if (this !== event.target) {
          event.stopPropagation();
        }
        if (this.classList.contains('selected')) {
          this.classList.remove('selected');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#drag-and-drop-' + that.context.intId).removeClass('selected');
          if (prev.length > 0) {
            prev.attr('src', '');
          }
          if (aud.length > 0) {
            aud[0].pause();
          }
        } else if (this.attributes['draggable']) {
          that.$el.find('.draggable').removeClass('selected');
          this.classList.add('selected');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#drag-and-drop-' + that.context.intId).addClass('selected');
          if (bri) {
            if (prev.length > 0) {
              prev.attr('src', bri.replace(/.*\s?url\(['"]?/, '').replace(/['"]?\).*/, ''));
            }
          }
          audSrc = this.attributes['soundClick'];
          if (!audSrc) {
            audSrc = this.attributes['soundOver'];
            if (audSrc) {
              audSrc = this.attributes['soundOver'].value;
            }
          } else {
            audSrc = this.attributes['soundClick'].value;
          }
          if (audSrc && aud.length > 0) {
            aud.attr('src', that.assets.sounds[parseInt(audSrc)]);
            aud[0].load();
            aud[0].play();
          }
        }
      });
      this.$el.find('.endpoint').on('click', function (event) {
        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$('#drag-and-drop-' + that.context.intId + '.selected').length === 0 || Ember.$('#drag-and-drop-' + that.context.intId + '.connecting').length > 0) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#drag-and-drop-' + that.context.intId).removeClass('connecting');
          return;
        }

        /* eslint-disable-next-line ember/no-jquery */
        var $selected = Ember.$('#drag-and-drop-' + that.context.intId + ' .connector.selected'),
          elem = document.getElementById($selected.attr('id')),
          correctArray = elem.attributes['validConnections'].value.split(' '),
          correct = false,
          i;
        if (elem.attributes['correctColor'].value === that.context.currentColor) {
          for (i = 0; i < correctArray.length; i++) {
            if (that.context.intId + '-endpoint-' + correctArray[i] === this.id) {
              correct = true;
              break;
            }
          }
        }
        event.preventDefault();

        /* eslint-disable-next-line ember/no-jquery */
        that.context.drawLine(document.getElementById(this.id), elem, that.context.currentColor, 3, correct, Ember.$('#drag-and-drop-' + that.context.intId));
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#drag-and-drop-' + that.context.intId).removeClass('selected');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#drag-and-drop-' + that.context.intId + ' .selected').removeClass('selected');
      });
      this.$el.find('.connector').on('click', function (event) {
        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$('#drag-and-drop-' + that.context.intId + '.selected').length > 0) {
          return;
        }

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#drag-and-drop-' + that.context.intId).addClass('connecting');
        if (this !== event.target) {
          event.stopPropagation();
        }
        if (this.classList.contains('selected')) {
          this.classList.remove('selected');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#drag-and-drop-' + that.context.intId).removeClass('selected');
        } else if (this.attributes['draggable']) {
          this.classList.add('selected');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#drag-and-drop-' + that.context.intId).addClass('selected');
        }
      });
      this.$el.find('.swatch').on('click', function () {
        that.context.currentColor = this.attributes['data-color'].value;
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#drag-and-drop-' + that.context.intId + ' .swatch').removeClass('current');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(this).addClass('current');
      });
      this.$el.on('click', '.connection', function () {
        var con = confirm('Are you sure you want to delete this connector?');
        if (con) {
          this.remove();
        }
      });
      this.$el.find('.connection-checker').on('click', function () {
        /* eslint-disable-next-line ember/no-jquery */
        var $incorrect = Ember.$('#drag-and-drop-' + that.context.intId + ' .connection.wrong');
        $incorrect.addClass('wrong-highlight');
      });

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('#feedback-mask-' + that.context.intId).click(function () {
        this.classList.remove('active');
      });
    }
  };
  var _default = _exports.default = DragAndDropQuiz;
});