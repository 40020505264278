define("bocce/controllers/classroom", ["exports", "bocce/mixins/menus", "bocce/mixins/calendar-events", "bocce/utilities/dialog", "bocce/mixins/notify", "bocce/mixins/helpguide", "bocce/mixins/enmodal"], function (_exports, _menus, _calendarEvents, _dialog, _notify, _helpguide, _enmodal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-observers */
  var _default = _exports.default = Ember.Controller.extend(_menus.default, _calendarEvents.default, _notify.default, _helpguide.default, _enmodal.default, {
    lessons: Ember.inject.controller('classroom.lessons'),
    conversations: Ember.inject.controller(),
    discussions: Ember.inject.controller(),
    classroom: Ember.inject.controller(),
    // required by EnmodalMixin
    heartbeat: Ember.inject.service(),
    session: Ember.inject.service(),
    bookmarks: Ember.inject.service(),
    userprofileService: Ember.inject.service('userprofile'),
    bookmarksService: Ember.computed.alias('bookmarks'),
    queryParams: ['start_panel'],
    start_panel: false,
    bobMaximized: false,
    bobClosed: true,
    pianoMaximized: false,
    switch_panel: Ember.observer('start_panel', function () {
      let startPanel = this.get('start_panel');

      // Validate panel to make sure it is one of the following: syllabus, conversation, activity, roster
      if (startPanel && ['syllabus', 'conversation', 'activity', 'roster'].includes(startPanel)) {
        this.send('swapPanel', startPanel);
      }
    }),
    // Variation on the Service Injection design pattern.
    // Using jQuery in the controller's unit tests breaks tests, since there is no DOM
    // and AJAX doesn't work with no interface to connect to. Referencing jQuery through
    // a property lets us mock the jQuery API in our unit tests.
    jQuery: Ember.$,
    topicLoading: Ember.computed.reads('lessons.topicLoading'),
    isBCM: Ember.computed.alias('model.classroom.term.isBCM'),
    lessonLoaded: Ember.computed.alias('session.lessonLoaded'),
    /* eslint-disable-next-line ember/no-observers */
    convoScroller: Ember.observer('activeConversation', function () {
      if (!this.activeConversation) {
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.modal-content').off('scrollstop', tgt => {
          if (tgt.target.scrollTop === 0) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.loading-prv').addClass('active');
            this.send('loadMoreMessages');
          }
        });
      }
    }),
    nextLessonMap: Ember.computed('model', 'model.classroom.lessons', function () {
      let map = {},
        model = this.model;
      let lessons = model.classroom.get('lessons');
      if (lessons) {
        return lessons.then(l_objs => {
          l_objs.forEach(lesson => {
            map[lesson.get('number')] = lesson;
          });
          return map;
        });
      }
      return map;
    }),
    bobSrc: Ember.computed(function () {
      let bobUrl = this.get('session.course.bobUrl');
      return 'https://' + bobUrl + '/?section=' + this.get('session.course.id');
    }),
    userSectionCodes: Ember.computed('model.classroom.id', 'session.user.sectionCodes', function () {
      // Returns the other sections the user is enrolled in,
      // including courses from other terms.
      /* eslint-disable-next-line ember/no-get */
      let sectionCodes = this.get('session.user.sectionCodes');
      if (sectionCodes === undefined) {
        return false;
      }
      sectionCodes = sectionCodes.filter(s => {
        /* eslint-disable-next-line ember/no-get */
        return Number(s.courseId) !== Number(this.get('model.classroom.id'));
      });
      if (sectionCodes.length === 0) {
        return false;
      }
      sectionCodes.sort((a, b) => {
        if (a.startsAt !== b.startsAt) {
          return a.startsAt > b.startsAt ? -1 : 1;
        } else if (a.courseTitle !== b.courseTitle) {
          return a.courseTitle < b.courseTitle ? -1 : 1;
        } else {
          return Number(a.sectionLabel) - Number(b.sectionLabel);
        }
      });
      return sectionCodes;
    }),
    otherCurrentTermSections: Ember.computed('userSectionCodes', 'session.course.term.name', function () {
      // Returns the other sections the user is enrolled in,
      // ONLY including the current course's term.
      let userSectionCodes = this.userSectionCodes || [];
      let otherCurrentTermSections = userSectionCodes.filter(section => {
        /* eslint-disable-next-line ember/no-get */
        return section.termLabel === this.get('session.course.term.name');
      });
      return otherCurrentTermSections;
    }),
    shouldOfferSurvey: Ember.computed('isBCM', 'model.classroom.term.weeksSinceStart', 'session.isStudent', function () {
      if (this.isBCM) {
        return false;
      }
      /* eslint-disable-next-line ember/no-get */
      let week = this.get('model.classroom.term.weeksSinceStart'),
        /* eslint-disable-next-line ember/no-get */
        max = this.get('session.isStudent') ? 14 : 15;
      let isFourWeekCourse = this.get('session.section.course.isFourWeekCourse');
      let surveyWeek = isFourWeekCourse ? 4 : 6;
      return week >= surveyWeek && week <= max;
    }),
    hasDiscourse: Ember.computed('model.classroom.discourseUrl', function () {
      // Check session to see course code
      /* eslint-disable-next-line ember/no-get */
      let courseCode = this.get('session.course.code');
      let limited = this.get("session.isLimitedStudentTerm");
      // Check if the CourseCode contains 'OLART-220'
      if (courseCode.includes('OLART-220') && !limited) {
        return true;
      }
      return false;
    }),
    surveyLabel: Ember.computed('model.classroom.term.weeksSinceStart', function () {
      return 'Survey';
    }),
    loadConversations: function () {
      let conversationsLoaded = this.conversationsLoaded || false,
        conversationsController = this.conversations,
        that = this;
      if (!conversationsLoaded) {
        return this.store.findAll('conversation').then(conversations => {
          conversationsController.set('model', conversations);
          that.set('conversationsLoaded', true);
        });
      }
    },
    loadEvents: function () {
      let eventsLoaded = this.eventsLoaded || false;
      // Reload events if we find ourselves in a different course
      if (this.currentCourseId != this.get('session.course.id')) {
        eventsLoaded = false;
        // Setting eventsLoaded here to display the loading-message partial in events.hbs
        this.set('eventsLoaded', false);
        this.currentCourseId = this.get('session.course.id');
      }
      // If there was a previous heartbeat error, restart the
      // heartbeat if we're within 30 minutes of a scheduled
      // event start time.
      let restart_heartbeat_if_needed = events => {
        if (window.heartbeatFrequencyInMilliseconds > 0) {
          return;
        }
        let now = new Date();
        events.forEach(evt => {
          let start = new Date(evt.get('startAt'));
          if (Math.abs(start - now) < 30 * 60 * 1000) {
            Ember.debug('Restarting heartbeat');
            window.heartbeatFrequencyInMilliseconds = 30 * 1000;
            /* eslint-disable-next-line ember/no-get */
            Ember.run.later(this, this.get('heartbeat.heartbeat'), 100);
          }
        });
      };
      if (!eventsLoaded) {
        return this.store.findAll('event').then(events => {
          // this.set('session.course.events', events);
          this.set('eventsLoaded', true);
          restart_heartbeat_if_needed(events);
        });
      } else {
        /* eslint-disable-next-line ember/no-get */
        restart_heartbeat_if_needed(this.get('session.course.events'));
      }
    },
    // lessonNumber and lessonTitle are given to the
    // Classroom::CurrentLessonItem component
    lessonNumber: Ember.computed.reads('lessons.model.lesson.number'),
    lessonTitle: Ember.computed.reads('lessons.model.item.title'),
    // this gets set using updateShortcutsDrawer action
    shortcutsDrawerLabel: 'Loading TOC...',
    hCodeURL: Ember.computed('session.section.id', /* eslint-disable-next-line ember/no-get */
    function () {
      return '/interface/sections/' + this.get('session.section.id') + '/acceptHonorCode';
    }),
    // courseDiscussions and courseAssignments are null at first, and are set
    // by the Route once the assignments/discussions findAll promises resolve.
    // These values are then passed to components.
    courseDiscussions: null,
    courseAssignments: null,
    courseRequiredDiscussions: null,
    courseQuizzes: null,
    activityContentLoading: Ember.computed('courseDiscussions', 'courseAssignments', function () {
      return this.courseDiscussions === null || this.courseAssignments === null;
    }),
    newAdviceCards: Ember.computed('session.section.adviceCards', 'session.section.adviceCards.adviceCardsCount', function () {
      let adviceCards = this.get('session.section.adviceCards');
      if (adviceCards) {
        return adviceCards.adviceCardsCount;
      }
      return false;
    }),
    submitAdviceCardPrompt: Ember.computed('session.section.adviceCards', 'session.section.adviceCards.iSubmitted', function () {
      let prefs = this.get('session.user.profile');
      return !(prefs?.declinedAdviceCardSubmission || false);
    }),
    iSubmitted: Ember.computed('session.section.adviceCards', 'session.section.adviceCards.iSubmitted', function () {
      return this.get('session.section.adviceCards.iSubmitted');
    }),
    isLastWeekOfCourse: Ember.computed('session.section.ends_at', 'session.course.lessons', function () {
      const lessons = this.get('session.course.lessons');
      if (lessons?.objectAt(lessons.length - 1)?.locked) {
        return false;
      }
      // Last lesson week starts 35 days before the course ends
      const lastLessonStarts = 35 * 24 * 60 * 60 * 1000;
      const endsAt = new Date(this.get('session.section.ends_at'));
      const now = new Date();
      return endsAt - now < lastLessonStarts;
    }),
    // TODO: NK: unreadActivities duplicates the logic from the all_unread
    // property on components/side-panel/activity.js. I'm leaving this as
    // duplicated code for now, but I'd like to find a way of DRYing this,
    // because we also use similar logic on the Work panel. Once the Work
    // panel is component-ized, that'll mean this same logic is needed in three
    // different places.
    unreadActivities: Ember.computed('session.user.profile.declinedAdviceCardSubmission', 'session.section.adviceCards', 'session.section.adviceCards.{adviceCardsCount,iSubmitted,isLastWeek}', 'courseDiscussions.@each.unread', 'courseAssignments.@each.{needsStudentAttention,needsTeacherAttention}', 'session.isInstructor', function () {
      let discussions = this.courseDiscussions || Ember.ArrayProxy.create();
      discussions = discussions.filter(d => !d.get('locked'));
      let unreadDiscussions = discussions.filterBy('unread');
      let assignments = this.courseAssignments || Ember.ArrayProxy.create();
      /* eslint-disable-next-line ember/no-get */
      let filter = this.get('session.isInstructor') ? 'needsTeacherAttention' : 'needsStudentAttention';
      let unreadAssignments = assignments.filterBy(filter);
      let retval = Ember.ArrayProxy.create({
        content: [...unreadDiscussions, ...unreadAssignments]
      });
      if (this.get('session.isInstructor') && this.session.section.adviceCards.adviceCardsCount) {
        for (let i = 0; i < this.session.section.adviceCards.adviceCardsCount; i++) {
          retval.pushObject([]);
        }
      }
      return retval;
    }),
    incompleteWork: Ember.computed('courseRequiredDiscussions.@each.todo', 'courseAssignments.@each.todo', 'courseQuizzes.@each.todo', function () {
      let discussions = this.courseRequiredDiscussions || [];
      let assignments = this.courseAssignments || [];
      let quizzes = this.courseQuizzes || [];
      let all = [...discussions.toArray(), ...assignments.toArray(), ...quizzes.toArray()];
      return all.filter(item => !item.get('locked') && item.get('todo'));
    }),
    adviceCardsURL: Ember.computed('session.course.id', 'session.course.code', 'session.section.id', function () {
      const courseId = this.get('session.course.id'),
        courseCode = this.get('session.course.code'),
        sectionId = this.get('session.section.id'),
        lastLessonNum = this.get('session.course.lessons.length') - 1;
      return `/#/${courseId}/${courseCode}/${sectionId}/${lastLessonNum}/peer-to-peer-support-advice-cards`;
    }),
    showBobInfoText: Ember.computed('session.dismissedBobInfoText', function () {
      let dismissedInfoText = localStorage.getItem('dismissedBobInfoText');
      return !dismissedInfoText;
    }),
    actions: {
      setShowLateGradingPolicy(show) {
        this.set('showLateGradingPolicy', show);
      },
      acceptHonorCode: function (firstTime, skipCheck) {
        if (!skipCheck) {
          /* eslint-disable-next-line ember/no-jquery */
          if (!Ember.$('#hcode-accept').is(':checked')) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.hcode-accept-checkbox').addClass('attention');
            return;
          }
        } else {
          this.set('session.section.needsHonorCode', false);
        }

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.hcode-accept').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.hcode-accept-checkbox').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.hcode-working').removeClass('hidden');
        this.jQuery.ajax({
          type: 'POST',
          url: this.hCodeURL,
          success: () => {
            Ember.debug('Honor code accepted');
            this.set('session.section.needsHonorCode', false);
            if (firstTime) {
              this.set('helpGuideOpen', true);
            }
          },
          error: error => {
            this.set('session.section.needsHonorCode', true);
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.hcode-accept').removeClass('hidden');
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.hcode-accept-checkbox').removeClass('hidden');
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.hcode-working').addClass('hidden');
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.hcode-accept-checkbox').removeClass('attention');
            (0, _dialog.default)('Unable to accept honor code. Something went wrong. Please try submitting again. If this error persists, contact support.');
            Ember.debug('Unable to accept honor code. Something went wrong: ');
            Ember.debug(error);
          }
        });
      },
      nextLesson: function (curLesson) {
        var model = this.model,
          lessons = model.classroom.get('lessons'),
          idx = model.classroom.get('lessons').mapBy('id').indexOf(curLesson.get('id')),
          nxt = idx + 1,
          nextLesson;
        if (nxt < lessons.get('length')) {
          nextLesson = lessons.objectAt(nxt);
          //$('.main-panel').scrollTop(0);
          this.transitionToRoute('classroom.lessons', nextLesson.get('id'), nextLesson.get('items.firstObject.id'));
        }
      },
      shortcut: function (lessonId) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.side-panel').scrollTop(0)
        /* eslint-disable-next-line ember/no-jquery */.scrollTop(Ember.$('.lesson-divider:nth(' + lessonId + ')').offset().top);
      },
      scrollToHeader: function (item, lesson) {
        var y;
        if (typeof item === 'number') {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.syllabus-divider.lesson-' + item).get(0).scrollIntoView();
          /* eslint-disable-next-line ember/no-jquery */
          y = Ember.$('.side-panel').scrollTop(); //your current y position on the page
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.side-panel').scrollTop(y + 20);
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#shortcuts-drawer-handle').prop('checked', false);
        } else {
          event.target.scrollIntoView();
        }
        this.transitionToRoute('classroom.lessons', lesson.get('id'), lesson.get('items.firstObject.id'));
      },
      openTOC: function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#shortcuts-drawer-handle').prop('checked', true);
      },
      closeTOC: function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#shortcuts-drawer-handle').prop('checked', false);
      },
      syllabusTransport: function (lessonId, topicId) {
        /* eslint-disable-next-line ember/no-jquery */
        var tgt = Ember.$('#syllabus-item-' + lessonId + '-' + topicId);
        if (!tgt.parent().hasClass('active')) {
          // Remove UI flourish from sylabus items for now
          // $('.syllabus-entry').removeClass('pbar');
          // tgt.addClass('pbar');
          this.lessons.set('interactionsBooted', false);
        }
      },
      // Click to swap between different left-side panels
      swapPanel: function (panel) {
        let that = this,
          $sp,
          $n;

        /* eslint-disable-next-line ember/no-jquery */
        $sp = Ember.$('.side-panel');
        /* eslint-disable-next-line ember/no-get */
        if (!this.get('session.panelPosition')) {
          this.set('session.panelPosition', {});
        }
        /* eslint-disable-next-line ember/no-get */
        this.set('session.panelPosition.' + this.get('session.panel'), $sp.scrollTop());
        $sp.scrollTop(0);
        $sp.removeClass('syllabus');
        /* eslint-disable-next-line ember/no-get */
        if (this.get('session.panel') === panel) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.classroom').toggleClass('active');
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.classroom').addClass('active');
          this.set('session.panel', panel);
          if (panel === 'syllabus') {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.side-panel').addClass('syllabus');
          }

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$.proxy(setTimeout(function () {
            $sp.scrollTop(that.get('session.panelPosition.' + panel) || 0);
            if (!that.get('session.panelPosition.' + panel)) {
              /* eslint-disable-next-line ember/no-jquery */
              $n = Ember.$('.side-panel .std-link.active');
              if ($n.length > 0) {
                $sp.scrollTop($n.position().top - $sp.height() + 100 + $sp.scrollTop());
              }
            }
          }, 100), this);
        }

        // JRW: Late load these types only when requested.
        if (panel === 'conversations') {
          this.loadConversations();
        } else if (panel === 'chat') {
          this.loadEvents();
        } else if (panel === 'activity') {
          /* eslint-disable-next-line ember/no-get */
          if (!this.get('session.isInstructor') && !this.studentActivityPanelFirstClick) {
            /* eslint-disable-next-line ember/no-jquery */
            if (Ember.$('.filter-unread-count.all').length > 0) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.filter-unread-count.all').click();
            }
            this.set('studentActivityPanelFirstClick', true);
          }
        }
      },
      gradeBook: function () {
        /* eslint-disable-next-line ember/no-get */
        if (!this.get('session.allowOldGradebook')) {
          this.set('gradebookOpen', true);
        } else {
          /* eslint-disable-next-line ember/no-get */
          var courseId = this.get('model.classroom.id');
          window.open('/courses/' + courseId + '/gradebook', '_blank');
        }
      },
      viewShowcase: function () {
        /* eslint-disable-next-line ember/no-get */
        var showcaseCourseID = this.get('session.section.showcase_course_id');
        window.open('/#/' + showcaseCourseID + '/' + showcaseCourseID + '/' + showcaseCourseID + '/', '_blank');
      },
      viewDiscourse: function () {
        var discourseURL = "https://discourse.online.berklee.edu/c/courses/olart-220-84/";
        window.open(discourseURL, '_blank');
      },
      viewAdviceCards: function () {
        if (this.get('session.isInstructor')) {
          try {
            fetch(`/interface/submit-advice-card/${this.get('session.section.id')}/mark_seen`, {
              method: 'POST'
            });
          } catch (e) {
            console.log(e);
          }
          this.set('session.section.adviceCards.adviceCardsCount', 0);
        }
        document.querySelector('.classroom').classList.remove('active');
      },
      declineAdviceCardSubmission: function () {
        let dialogPromise = (0, _dialog.default)('Are you sure you want to remove this reminder? This cannot be undone.', ['Yes', 'No']);
        dialogPromise.then(selection => {
          if (selection === 'Yes') {
            console.log('Declining advice card submission');
            let prefs = this.get('session.user.profile') || {};
            let courseCode = this.get('session.course.code');
            prefs.declinedAdviceCardSubmission = courseCode;
            this.store.findRecord('user', this.get('session.user.id')).then(record => {
              record.set('profile_updated', true);
              record.set('profile', prefs);
              record.save();
            });
            this.set('classroom.submitAdviceCardPrompt', false);
            // this.set('session.section.adviceCards.iSubmitted', true);
          }
        });
      },
      closePopup: function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.popup-container').removeAttr('closeId');

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.popup-container .content').html('');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.popup-container').removeClass('active');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.popup-backdrop').removeClass('active');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.side-panel').removeClass('on-modal');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.main-panel').removeClass('no-scroll');
      },
      toggleCourseList: function () {
        /* eslint-disable-next-line ember/no-jquery */
        var $chbx_toggle = Ember.$('#chbx-course-select-drawer-handle');
        /* eslint-disable-next-line ember/no-jquery */
        var $course_toggle = Ember.$('.course-select-drawer-handle');
        /* eslint-disable-next-line ember/no-jquery */
        var $course_list = Ember.$('.course-list-courses');
        if ($chbx_toggle.prop('checked')) {
          $chbx_toggle.prop('checked', false);
          $course_toggle.removeClass('open');
          $course_list.removeClass('open');
        } else {
          $chbx_toggle.prop('checked', true);
          $course_toggle.addClass('open');
          $course_list.addClass('open');
        }
      },
      closeCourseList: function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#chbx-course-select-drawer-handle').prop('checked', false);
      },
      switchCourse: function (userSectionCode) {
        // TODO: Change from hard page refresh to load ember classroom correctly without refresh.
        let path = 'https://' + window.location.hostname + '/#/' + userSectionCode.courseId + '/' + userSectionCode.code + '/' + userSectionCode.sectionId;
        window.location.href = path;
        window.location.reload();
      },
      //Change playback speed of audio player
      changePlaybackSpeed: function (value) {
        var speedCalc = parseFloat(value) / 100;
        document.getElementById('publicPlayer').playbackRate = speedCalc;
      },
      // Submit bug report
      bugReport: function () {
        //window.open('https://docs.google.com/forms/d/1TNgug-nMiauaMUmzju4EeGO8_BttNNyTUaSXebyuN8g/viewform', '_blank');
        // Using Luke's Simpler form
        window.open('https://online.berklee.edu/student-support-contact', '_blank');
      },
      // qualtrics survey
      takeSurvey: function () {
        this.transitionToRoute('classroom.lessons.survey', 'course');
      },
      maxMinBob: function () {
        this.set('bobMaximized', !this.bobMaximized);
        this.set('bobClosed', false);
        let isBobMaximized = this.get('bobMaximized');
        if (isBobMaximized) {
          Ember.$('.classroom').removeClass('active');
        } else {
          Ember.$('.classroom').addClass('active');
        }
      },
      closeBob: function () {
        this.set('bobClosed', true);
        this.set('bobMaximized', false);
        Ember.$('.classroom').addClass('active');
      },
      openBob: function () {
        if (!this.get('bobSrc')) {
          let bobUrl = this.get('session.course.bobUrl');
          this.set('bobSrc', 'https://' + bobUrl + '/?section=' + this.get('session.course.id'));
        } else {
          this.send('sendUrlToBob');
        }
        this.set('bobClosed', false);
        this.set('bobMaximized', false);
        this.send('dismissBobInfoText');
      },
      sendUrlToBob() {
        const bobIframe = document.querySelector('#bob-contents iframe');
        if (bobIframe) {
          const fullUrl = window.location.href;
          const bobOrigin = new URL(this.get('bobSrc')).origin;
          bobIframe.contentWindow.postMessage({
            bocce_url: fullUrl
          }, bobOrigin);
        }
      },
      dismissBobInfoText() {
        localStorage.setItem("dismissedBobInfoText", "true");
        this.set('session.dismissedBobInfoText', true);
      },
      maxMinPiano: function () {
        this.set('pianoMaximized', !this.pianoMaximized);
      }
    },
    sendFeedback: function () {
      // opens a form where the user can send feedback, which opens a Salesforce case.
      /* eslint-disable-next-line ember/no-get */
      let userEmail = this.get('session.user.email');
      window.open(`https://online.berklee.edu/form_assembly?id=4708322&URLemail=${userEmail}`, '_blank');
    },
    openAdminModal: function () {
      this.transitionToRoute('classroom.lessons.admin');
    },
    helpSection: function () {
      this.set('helpGuideOpen', true);
    }
  });
});