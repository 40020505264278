define("bocce/mixins/interactions/form_builder", ["exports", "bocce/mixins/support/util", "bocce/mixins/support/render-template"], function (_exports, util, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function FormBuilder($el, data_raw) {
    let data = {
      "exportButton": {
        "text": "Get PDF"
      }
    };
    data.initialRows = data_raw.config.initial_rows;
    data.title = data_raw.config.title;
    let dataTable = $el.find('.interaction_data table')[0];
    data_raw.labels = FormBuilder.GetDataFromTable(dataTable);
    data.headers = data_raw.labels.headers;
    data.rows = data_raw.labels.rows;
    this.rowsLabelled = data_raw.labels.rowsLabelled;
    this.columnsLabelled = data_raw.labels.columnsLabelled;
    this.$el = $el;
    this.data = data;
    this.$containerEl = $el.find('.interaction_content');
    this.$containerEl.removeAttr('style'); // remove inline styles from the container element
    this.data = data;
    this.selectedCell = "";
    this.globalSetting = {
      fontSize: "13",
      color: "#000000"
    };
    this.selectedImageData = {
      filename: "",
      imageData: ""
    };
    this.initialRows = this.data.initialRows;
    this.rowNumCounter = 0;
    this.headers = this.data.headers;
    this.tableDetail = {
      "titles": this.headers,
      "bodyDetails": []
    };
  }
  FormBuilder.prototype = {
    init() {
      return (0, _renderTemplate.default)('form_builder', 'main', this.data).then(content => {
        this.$containerEl.append(content);
        this.$tableContainer = this.$containerEl.find('.table-content-wrapper');
        this.$boldToolbarBtn = this.$containerEl.find('.toolbar-btn-bold');
        this.$italicToolbarBtn = this.$containerEl.find('.toolbar-btn-italic');
        this.$underlineToolbarBtn = this.$containerEl.find('.toolbar-btn-underline');
        this.$colorPickerToolbarBtn = this.$containerEl.find('.toolbar-btn-color-picker');
        this.$fontSizeToolbarBtn = this.$containerEl.find('.toolbar-btn-font-size');
        this.$addImageToolbarBtn = this.$containerEl.find('.toolbar-btn-add-image');
        this.$addRowBtn = this.$containerEl.find('.add-btn');
        this.$removeRowBtn = this.$containerEl.find('.remove-btn');
        this.$exportBtn = this.$containerEl.find('.export-btn');
        this.$tableContainer.empty();
        this.createTable();
      });
    },
    createTable() {
      let self = this;
      self.$table = Ember.$('<table class="interaction-table"></table>');

      // Add Headers
      if (this.columnsLabelled) {
        self.$tableHeader = Ember.$('<thead class="interaction-table-content"></thead>');
        self.addHeaderRows();
        self.$table.append(self.$tableHeader);
      }

      //Add table body cells
      self.$tableBody = Ember.$('<tbody class="interaction-table-content"></tbody>');
      self.addBodyRows(self.initialRows);
      self.$table.append(self.$tableBody);
      self.$tableContainer.append(self.$table);

      // Set table width to 100px * number of columns
      self.$table.css('min-width', `${self.headers.length * 100}px`);

      // add Button Events
      self.addEvents();
    },
    addEvents() {
      let self = this;
      Ember.$("body").on("click", function (evt) {
        //Only need to unselect the form if it has been selected. 
        //This ensures that interactions with the page are not unnecessarily blocked.
        //Ex: audio files are still playable when the form builder is on the same page.
        if (self.$el.data('selected')) {
          evt.stopImmediatePropagation();
          self.$el.find("td").removeClass("selected");
          self.selectedCell = "";
          self.$colorPickerToolbarBtn[0].value = self.globalSetting.color;
          self.$fontSizeToolbarBtn[0].value = self.globalSetting.fontSize;
          self.$boldToolbarBtn.removeClass("selected");
          self.$italicToolbarBtn.removeClass("selected");
          self.$underlineToolbarBtn.addClass("selected");
          self.$underlineToolbarBtn.removeClass("selected");
          self.$addImageToolbarBtn.removeClass("selected");
          self.$el.data('selected', false);
        }
      });

      //The third param basically ensures that stopPropagation when used
      //in this form doesn't prevent this listener from being called.
      self.$el.get(0).addEventListener("click", function (evt) {
        //This data gets used in the body 'click' listener to determine 
        //whether there is a need to unselect the form.
        Ember.$(this).data("selected", true);
      }, true);
      self.$addRowBtn.on("click", function () {
        self.addBodyRows(1, true);
      });
      self.$removeRowBtn.on("click", function () {
        if (self.$tableBody.find("tr").length > 1 && (self.data.rows && self.data.rows.length < self.rowNumCounter || !self.data.rows)) {
          self.$tableBody.find("tr:last-child").remove();
          self.rowNumCounter--;
          self.tableDetail.bodyDetails.pop();
        }
        if (self.rowNumCounter <= 1 || self.data.rows && self.data.rows.length <= self.rowNumCounter) {
          Ember.$(this).addClass("disabled").removeClass("tababble");
          self.$addRowBtn.focus();
        }
      });
      self.$exportBtn.on("click", function () {
        self.generatePDF();
      });
      self.$boldToolbarBtn.on("click", function (evt) {
        evt.stopPropagation();
        if (self.selectedCell) {
          let currCell = Ember.$("#" + self.selectedCell);
          let cellLocation = self.selectedCell.split("_")[1].split("-");
          let rowId = parseInt(cellLocation[0]) - 1;
          let cellId = parseInt(cellLocation[1]) - 1;
          let isBold = false;
          if (currCell.hasClass("selected")) {
            if (currCell.hasClass("textBold")) {
              isBold = false;
              currCell.removeClass("textBold").find("textarea").css("font-weight", "normal");
              Ember.$(this).removeClass("selected");
            } else {
              isBold = true;
              currCell.addClass("textBold").find("textarea").css("font-weight", "bold");
              Ember.$(this).addClass("selected");
            }
            self.tableDetail.bodyDetails[rowId][cellId].bold = isBold;
          }
        }
      });
      self.$italicToolbarBtn.on("click", function (evt) {
        evt.stopPropagation();
        if (self.selectedCell) {
          let currCell = Ember.$("#" + self.selectedCell);
          let cellLocation = self.selectedCell.split("_")[1].split("-");
          let rowId = parseInt(cellLocation[0]) - 1;
          let cellId = parseInt(cellLocation[1]) - 1;
          let isItalic = false;
          if (currCell.hasClass("selected")) {
            if (currCell.hasClass("textItalic")) {
              isItalic = false;
              currCell.removeClass("textItalic").find("textarea").css("font-style", "normal");
              Ember.$(this).removeClass("selected");
            } else {
              isItalic = true;
              currCell.addClass("textItalic").find("textarea").css("font-style", "italic");
              Ember.$(this).addClass("selected");
            }
            self.tableDetail.bodyDetails[rowId][cellId].italics = isItalic;
          }
        }
      });
      self.$underlineToolbarBtn.on("click", function (evt) {
        evt.stopPropagation();
        if (self.selectedCell) {
          let currCell = Ember.$("#" + self.selectedCell);
          let cellLocation = self.selectedCell.split("_")[1].split("-");
          let rowId = parseInt(cellLocation[0]) - 1;
          let cellId = parseInt(cellLocation[1]) - 1;
          let isUnderline = false;
          if (currCell.hasClass("selected")) {
            if (currCell.hasClass("textUnderline")) {
              isUnderline = "";
              currCell.removeClass("textUnderline").find("textarea").css("text-decoration", "none");
              Ember.$(this).removeClass("selected");
            } else {
              isUnderline = "underline";
              currCell.addClass("textUnderline").find("textarea").css("text-decoration", "underline");
              Ember.$(this).addClass("selected");
            }
            self.tableDetail.bodyDetails[rowId][cellId].decoration = isUnderline;
          }
        }
      });
      self.$colorPickerToolbarBtn.on("click", function (evt) {
        evt.stopPropagation();
      });
      self.$colorPickerToolbarBtn.on("input", function (evt) {
        evt.stopPropagation();
        let val = this.value;
        if (self.selectedCell) {
          let currCell = Ember.$("#" + self.selectedCell);
          let cellLocation = self.selectedCell.split("_")[1].split("-");
          let rowId = parseInt(cellLocation[0]) - 1;
          let cellId = parseInt(cellLocation[1]) - 1;
          if (currCell.hasClass("selected")) {
            currCell.addClass("textColorChanged").find("textarea").css("color", val);
            self.tableDetail.bodyDetails[rowId][cellId].color = val;
            currCell.find("textarea")[0].style.height = "auto";
            currCell.find("textarea")[0].style.height = currCell.find("textarea")[0].scrollHeight + "px";
          }
        } else {
          self.$el.find("textarea").css("color", val).addClass("textColorChanged");
          Ember.$.each(self.$el.find("textarea"), function () {
            this.style.height = "auto";
            this.style.height = this.scrollHeight + "px";
          });
          self.globalSetting.color = val;
          for (let i = 0; i < self.tableDetail.bodyDetails.length; i++) {
            for (let j = 0; j < self.tableDetail.bodyDetails[i].length; j++) {
              self.tableDetail.bodyDetails[i][j].color = val;
            }
          }
        }
      });
      self.$fontSizeToolbarBtn.on("click", function (evt) {
        evt.stopPropagation();
      });
      self.$fontSizeToolbarBtn.on("change", function (evt) {
        evt.stopPropagation();
        let val = this.value;
        if (self.selectedCell) {
          let currCell = Ember.$("#" + self.selectedCell);
          let cellLocation = self.selectedCell.split("_")[1].split("-");
          let rowId = parseInt(cellLocation[0]) - 1;
          let cellId = parseInt(cellLocation[1]) - 1;
          if (currCell.hasClass("selected")) {
            currCell.addClass("textfontChanged").find("textarea").css("font-size", val + "px");
            self.tableDetail.bodyDetails[rowId][cellId].fontSize = val;
            currCell.find("textarea")[0].style.height = "auto";
            currCell.find("textarea")[0].style.height = currCell.find("textarea")[0].scrollHeight + "px";
          }
        } else {
          self.$el.find("textarea").css("font-size", val + "px").addClass("textfontChanged");
          self.globalSetting.fontSize = val;
          Ember.$.each(self.$el.find("textarea"), function () {
            this.style.height = "auto";
            this.style.height = this.scrollHeight + "px";
          });
          for (let i = 0; i < self.tableDetail.bodyDetails.length; i++) {
            for (let j = 0; j < self.tableDetail.bodyDetails[i].length; j++) {
              self.tableDetail.bodyDetails[i][j].fontSize = val;
            }
          }
        }
      });
      self.$addImageToolbarBtn.on("click", function (evt) {
        evt.stopPropagation();
        if (self.selectedCell) {
          let $thisElement = Ember.$(this);
          let f = document.createElement('input');
          f.type = 'file';
          f.name = 'file';
          $thisElement.addClass("selected");
          self.$el.find(".selected-image-name").removeClass("selected");
          self.selectedImageData = {
            filename: "",
            imageData: ""
          };
          Ember.$(f).off("change").on("change", function () {
            util.encodeImageFileAsURL(f).then(function (value) {
              $thisElement.removeClass("selected");
              self.selectedImageData = {
                filename: f.files[0].name,
                imageData: value
              };
              if (value) {
                let currCell = Ember.$("#" + self.selectedCell);
                let cellLocation = self.selectedCell.split("_")[1].split("-");
                let rowId = parseInt(cellLocation[0]) - 1;
                let cellId = parseInt(cellLocation[1]) - 1;
                if (currCell.hasClass("selected")) {
                  if (currCell.hasClass("textImageAdded") && currCell.find("img").length) {
                    currCell.find("img").attr("src", value);
                  } else {
                    currCell.addClass("textImageAdded").prepend('<div class="image-del-prompt">Delete Image</div>');
                    currCell.addClass("textImageAdded").prepend('<img src="' + value + '">');
                    currCell.find(".image-del-prompt").on("click", evt => {
                      evt.stopPropagation();
                      Ember.$(evt.target).prev().remove();
                      Ember.$(evt.target).remove();
                    });
                  }
                  self.tableDetail.bodyDetails[rowId][cellId].image = value;
                  currCell.find("textarea")[0].style.height = "auto";
                  currCell.find("textarea")[0].style.height = currCell.find("textarea")[0].scrollHeight + "px";
                }
              }
            }, function (error) {
              alert(error);
            });
          });
          f.dispatchEvent(new MouseEvent("click"));
        }
      });
    },
    addHeaderRows() {
      let self = this;
      let $tRow = Ember.$('<tr id="tableRow_0" class="interaction-table-row"></tr>');
      let $tHeadCell = Ember.$('<th id="tableHead_1" class="interaction-table-head"> </th>');
      if (self.$table == null || self.$table === "" || self.$table.length < 1) {
        self.$table = Ember.$('<table class="interaction-table"></table>');
      }
      if (self.$tableHeader == null || self.$tableHeader === "" || self.$tableHeader.length < 1) {
        self.$tableHeader = Ember.$('<thead class="interaction-table-content"></thead>');
      }
      let tH_Row = $tRow.clone();
      tH_Row.attr("id", "tableRow_0");
      if (self.rowsLabelled) {
        // add empty string to self.tableDetail.titles for PDF generation
        self.tableDetail.titles.unshift("");
      }
      for (let i = 0; i < self.headers.length; i++) {
        const node = self.headers[i];
        let hCell = $tHeadCell.clone();
        hCell.attr({
          "id": "tableHead_" + (i + 1),
          "aria-label": node
        }).html(node);
        tH_Row.append(hCell);
      }
      self.$tableHeader.append(tH_Row);
    },
    addBodyRows(rowCount, blankRows) {
      self = this;
      let $tRow = Ember.$('<tr id="tableRow_0" class="interaction-table-row"></tr>');
      let $tbodyCell = Ember.$('<td id="tableBody_1" class="interaction-table-data"> <textarea class="input-field" type="text"></textarea></td>');
      let onInputChangeEvent = function () {
        let id = Ember.$(this).attr("id").split("_")[1].split("-");
        let rowId = parseInt(id[0]) - 1;
        let cellId = parseInt(id[1]) - 1;
        self.tableDetail.bodyDetails[rowId][cellId].text = Ember.$(this).val();
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
      };
      let onFocusEvent = function () {
        self.$el.find("td").removeClass("selected");
        let $selectedTD = Ember.$(this).closest("td");
        $selectedTD.addClass("selected");
        self.selectedCell = $selectedTD.attr("id");
        let cellLocation = self.selectedCell.split("_")[1].split("-");
        let rowId = parseInt(cellLocation[0]) - 1;
        let cellId = parseInt(cellLocation[1]) - 1;
        let currNodeItem = self.tableDetail.bodyDetails[rowId][cellId];
        if (currNodeItem.color) {
          self.$colorPickerToolbarBtn[0].value = currNodeItem.color;
        } else {
          self.$colorPickerToolbarBtn[0].value = "#000000";
        }
        if (currNodeItem.fontSize) {
          self.$fontSizeToolbarBtn[0].value = currNodeItem.fontSize;
        } else {
          self.$fontSizeToolbarBtn[0].value = "13";
        }
        if (currNodeItem.bold) {
          self.$boldToolbarBtn.addClass("selected");
        } else {
          self.$boldToolbarBtn.removeClass("selected");
        }
        if (currNodeItem.italics) {
          self.$italicToolbarBtn.addClass("selected");
        } else {
          self.$italicToolbarBtn.removeClass("selected");
        }
        if (currNodeItem.decoration) {
          self.$underlineToolbarBtn.addClass("selected");
        } else {
          self.$underlineToolbarBtn.removeClass("selected");
        }
      };
      if (self.$tableBody == null || self.$tableBody === "" || self.$tableBody.length < 1) {
        self.$tableBody = Ember.$('<tbody class="interaction-table-content"></tbody>');
      }
      for (let j = 0; j < rowCount; j++) {
        let tB_Row = $tRow.clone();
        let bodyDetailsRow = [];
        self.rowNumCounter++;
        let rowCounter = self.rowNumCounter;
        tB_Row.attr("id", "tableRow_" + rowCounter);
        let addCol = 0;
        if (!blankRows && self.rowsLabelled && self.data.rows[j] && self.data.rows[j].length > 0) {
          // label the row with the contents of data.rows
          // These cells don't need a textarea or any events
          let rowLabel = self.data.rows[j] || "";
          let tB_Cell = Ember.$('<td id="tableBody_' + rowCounter + '-0" class="interaction-table-data interaction-table-head">' + rowLabel + '</td>');
          tB_Row.append(tB_Cell);
          addCol = 1;
          bodyDetailsRow.push({
            "title": "Label",
            "text": rowLabel
          });
        }
        let cellNum = self.headers.length - addCol;
        for (let i = 0; i < cellNum; i++) {
          const node = self.headers[i];
          let cellCounter = i + 1 + addCol;
          let bCell = $tbodyCell.clone();
          bodyDetailsRow.push({
            "title": node,
            "text": ""
          });
          bCell.attr("id", "tableBody_" + rowCounter + "-" + cellCounter).on("click", function (evt) {
            evt.stopPropagation();
            Ember.$(this).find(".input-field").focus();
          });
          bCell.find(".input-field").attr({
            "id": "inputField_" + rowCounter + "-" + cellCounter,
            "for": "tableHead_" + cellCounter,
            "aria-label": "add " + node
          }).off("input", onInputChangeEvent).off("focus", onFocusEvent).on("input", onInputChangeEvent).on("focus", onFocusEvent).addClass("tababble").css({
            "overflow-y": "hidden"
          });
          tB_Row.append(bCell);
        }
        self.tableDetail.bodyDetails.push(bodyDetailsRow);
        self.$tableBody.append(tB_Row);
      }
      if (self.$tableBody.find("tr").length > 1 && (self.data.rows && self.data.rows.length <= self.rowNumCounter || !self.data.rows)) {
        self.$removeRowBtn.removeClass("disabled").addClass("tababble");
      } else {
        self.$removeRowBtn.addClass("disabled").removeClass("tababble");
      }
    },
    generatePDF() {
      let self = this;
      let genericRows = [];
      let eachColWidths = [];

      // add Headers
      let headerRow = [];
      for (let i = 0; i < self.tableDetail.titles.length; i++) {
        const headerText = self.tableDetail.titles[i];
        headerRow.push({
          text: headerText,
          style: 'tableHeader',
          bold: true,
          alignment: 'center',
          fillColor: '#ccc',
          color: '#000',
          fontSize: 6,
          margin: [1, 1, 1, 1]
        });
        eachColWidths.push("auto");
      }
      genericRows.push(headerRow);
      // add body
      let bodyRows = [];
      for (let i = 0; i < self.tableDetail.bodyDetails.length; i++) {
        const bRow = self.tableDetail.bodyDetails[i];
        let rowCell = [];
        for (let j = 0; j < bRow.length; j++) {
          const bCell = bRow[j];
          if (bCell.title === "Label") {
            rowCell.push({
              text: bCell.text,
              style: 'tableHeader',
              bold: true,
              alignment: 'center',
              fillColor: '#ccc',
              color: '#000',
              fontSize: 6,
              margin: [1, 1, 1, 1]
            });
          } else {
            let tempNode = {
              text: bCell.text,
              color: '#000',
              fontSize: 6,
              margin: [1, 1, 1, 1]
            };
            if (bCell.bold) {
              tempNode.bold = bCell.bold;
            }
            if (bCell.italics) {
              tempNode.italics = bCell.italics;
            }
            if (bCell.decoration) {
              tempNode.decoration = bCell.decoration;
            }
            if (bCell.color) {
              tempNode.color = bCell.color;
            }
            if (bCell.fontSize) {
              let numDiff = parseInt(bCell.fontSize) - 13;
              tempNode.fontSize = 6 + numDiff;
            }
            if (bCell.image) {
              rowCell.push({
                stack: [{
                  image: bCell.image,
                  alignment: 'center',
                  width: 30
                }, tempNode]
              });
            } else {
              rowCell.push(tempNode);
            }
          }
        }
        bodyRows.push(rowCell);
      }
      for (let k = 0; k < bodyRows.length; k++) {
        const rowItem = bodyRows[k];
        genericRows.push(rowItem);
      }

      // Get today's date in format dd/mm/yyyy
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1; //January is 0!
      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      let date = dd + '/' + mm + '/' + yyyy;

      // Define PDF output - See http://pdfmake.org/#/gettingstarted for information on creating a PDF document with pdfmake.
      let docDefinition = {
        // Set page size
        pageSize: 'A4',
        // Set document orientation
        pageOrientation: 'portrait',
        // Define main body of document
        content: [{
          text: self.data.title,
          fontSize: 16,
          alignment: 'center',
          margin: [0, 0, 0, 20],
          color: '#212121',
          bold: true
        }, {
          style: 'tableExample',
          margin: [-15, 0, 0, 0],
          table: {
            margin: [-15, 0, 0, 0],
            headerRows: 1,
            widths: eachColWidths,
            body: genericRows
          }
        }]
      };

      // Function to generate and download the PDF using the document definition object (docDefinition) we defined previously.
      pdfMake.createPdf(docDefinition).download(self.data.title + '.pdf');
    }
  };
  FormBuilder.GetDataFromTable = function (table) {
    let data = {};
    // Extract "header labels" from the first column of the table, skipping the first row (which is the header row)
    // Extract "row labels" from the second column of the table, skipping the first row (which is the header row)

    let headerLabels = [];
    let rowLabels = [];
    let rows = Ember.$(table).find("tr");
    for (let i = 1; i < rows.length; i++) {
      let row = rows[i];
      let cells = Ember.$(row).find("td");
      if (cells.length > 0) {
        let columnLabel = Ember.$(cells[0]).text();
        if (columnLabel.length > 0) {
          headerLabels.push(columnLabel);
          data.columnsLabelled = true;
        }
        if (cells.length > 1) {
          let rowLabel = Ember.$(cells[1]).text();
          if (rowLabel.length > 0) {
            data.rowsLabelled = true;
            rowLabels.push(rowLabel);
          }
        } else {
          rowLabels.push("");
        }
      }
    }
    data.rowsLabelled = true;
    data.headers = headerLabels;
    data.rows = rowLabels;
    return data;
  };
  var _default = _exports.default = FormBuilder;
});